.menubar-container {
  margin: 5px 10px 5px 10px;
  max-height: 20px;
  min-height: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
}

.menubar-icon {
  cursor: pointer;
  margin: 3px;
}
