/* The defaults for desktop, laptop, and larger tablets: */
/* only want a 500px width to kinda make it look  */
/* like on a mobile device */
:root {
  --responsive-width: 500px;
  --responsive-overlayWidth: 500px;
  --responsive-overlayMarginLeft: calc(50% - 250px);
  --responsive-tabViewTitlePadding: 1rem;
  --responsive-inputGroupWidth: 300px;

  --responsive-helpScreenItemHeight: calc(100vh - 17rem);

  --tabPanelContentHeight: calc(100vh - 7rem);
  --sidebarHeight: calc(100% - 1rem);
  --sidebarContentHeight: calc(100% - 2rem);
  --welcomeFormContainerHeight: calc(100vh - 7rem);
}

/* Smaller tablets and mobile, want to fill whole screen */
@media only screen and (max-device-width: 700px) and (max-width: 700px) {
  :root {
    --responsive-width: calc(100% - 10px);
    --responsive-overlayWidth: calc(100% - 10px);
    --responsive-overlayMarginLeft: 4px;
    --responsive-tabViewTitlePadding: 0.5rem;
    --responsive-inputGroupWidth: 240px;

    --responsive-helpScreenItemHeight: calc(100vh - 15rem);
  }
}

/* mobile and tablet portrait */
@media only screen and (max-device-height: 823px) and (max-height: 823px) and (orientation: portrait) {
  :root {
    --responsive-helpScreenItemHeight: calc(100vh - 15rem);
  }
}

/* mobile and tablet landscape: */
/* need to fix:  */
/* iphone x (landscape width x height: 812 x 375) */
/* pixel 2 xl (landscape width x height: 823 x 411),  */
@media only screen and (max-device-height: 540px) and (max-height: 540px) and (orientation: landscape) {
  :root {
    --responsive-helpScreenItemHeight: calc(100vh - 7.5rem);
  }
}

.textotals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.apploading-container {
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  height: var(--tabPanelContentHeight);
  min-height: var(--tabPanelContentHeight);
}

.sidebar-container {
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.textotals-appcard {
  margin: 1rem;
  align-items: center;
  text-align: center;
  width: var(--responsive-width);
  max-width: var(--responsive-width);
  min-width: var(--responsive-width);
}

.textotals-tabview {
  vertical-align: middle;
  width: 100%;
  border-bottom-right-radius: 3% !important;
  border-bottom-left-radius: 3% !important;
}

.textotals-tabpanel-content {
  height: var(--tabPanelContentHeight);
  font-size: calc(10px + 2vmin);
  /* border-radius: 3%; */
  border-bottom-right-radius: 3% !important;
  border-bottom-left-radius: 3% !important;
  /* border: 2px solid red; */
  /* background-color: #e5e5f7; */
  background: linear-gradient(135deg, #444cf755 25%, transparent 25%) -18px 0/
      36px 36px,
    linear-gradient(225deg, #444cf7 25%, transparent 25%) -18px 0/ 36px 36px,
    linear-gradient(315deg, #444cf755 25%, transparent 25%) 0px 0/ 36px 36px,
    linear-gradient(45deg, #444cf7 25%, #e5e5f7 25%) 0px 0/ 36px 36px;
}

.p-sidebar-content {
  height: var(--sidebarHeight);
}

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-card .p-card-body {
  padding: 0 !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-tabview-nav-link {
  padding-bottom: 5px !important;
}

.p-menubar {
  padding: 0px !important;
  margin-left: 5px;
  margin-right: 5px;
  border: unset !important;
}

.p-grid {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  flex-wrap: nowrap !important;
}

ul {
  margin-block-start: unset !important;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem 0.5rem !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem !important;
}

.p-card {
  border: 2px solid blue !important;
  border-radius: 3% !important;
}

.p-sidebar-content {
  height: var(--sidebarContentHeight) !important;
}

.p-carousel-content {
  height: calc(var(--sidebarContentHeight) - 1rem) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: var(--responsive-tabViewTitlePadding) !important;
}

.p-inputtext.p-inputtext-lg {
  padding: 0 !important;
}

.p-inputtext {
  padding: 0 !important;
}
