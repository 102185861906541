.premiumform-container {
  height: calc(100vh - 7rem);
  min-height: calc(100vh - 7rem);

  /* height: 100%; */
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.premiumform-inputgroup {
  margin: 25px 0px 20px 0px;
  min-width: var(--responsive-inputGroupWidth) !important;
  width: var(--responsive-inputGroupWidth) !important;
}
