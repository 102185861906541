.welcome-topcontainer {
  background-color: #444cf755;
}

.welcome-container {
  height: var(--welcomeFormContainerHeight);
  min-height: var(--welcomeFormContainerHeight);
  width: 100%;
  border-radius: 1%;
  padding-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.welcome-item {
  margin: 25px 0px 20px 0px;
}
