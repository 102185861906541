.registerform-container {
  height: var(--welcomeFormContainerHeight);
  min-height: var(--welcomeFormContainerHeight);
  width: 100%;

  /* height: 100%; */
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registerform-inputgroup {
  margin: 0px 10px 0px 10px;
  min-width: var(--responsive-inputGroupWidth) !important;
  width: var(--responsive-inputGroupWidth) !important;
}

.registerform-inputgroup-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registerform-inputgroup-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
